<template>
  <CHeader position="sticky" class="p-0 mb-4" :class="element">
    <CContainer fluid class="p-3 mb-2 bg-light text-dark ">
      <CHeaderToggler class="bg-black text-white" @click="$store.commit('toggleSidebar')">
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>

      <!-- <CHeaderNav class="d-md-flex me-auto ">
        <CForm class="d-flex" style="margin-left:20px">
          <CFormInput type="search" class="me-2" placeholder="Search" />
          <CButton class="bg-black text-white" type="submit" color="primary" variant="outline">Search</CButton>
        </CForm>
      </CHeaderNav> -->
      <CHeaderNav>
        <CNavbar color-scheme="light" class="bg-whigt">
        </CNavbar>
        <AppHeaderDropdownAccnt />
        <!-- <CNavItem>
          <CNavLink href="#">
            <CIcon class="mx-2" icon="cil-bell" size="lg" />
          </CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink href="#">
            <CIcon class="mx-2" icon="cil-list" size="lg" />
          </CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink href="#">
            <CIcon class="mx-2" icon="cil-envelope-open" size="lg" />
          </CNavLink>
        </CNavItem> -->
        <CNavItem>
          <CNavLink href="#">
            <CButton icon="cil-lock-locked" v-on:click="logout()"><strong> Logout</strong></CButton>
          </CNavLink>
        </CNavItem>
      </CHeaderNav>
    </CContainer>
    <CHeaderDivider />

  </CHeader>
</template>

<script>
import AppBreadcrumb from './AppBreadcrumb'
import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt'
import {
  logo
} from '@/assets/brand/logo'
export default {
  name: 'AppHeader',
  components: {
    // AppBreadcrumb,
    AppHeaderDropdownAccnt,
  },
  setup() {
    return {
      logo,
    }
  },
  methods: {
    logout() {
      // console.log("Radhe")
      localStorage.removeItem('nerpafs_admin_id');
      // this.$router.go('/pages/login')
      this.$router.push({
        path: '/admin/login'
      })

    }
  }
}
</script>
