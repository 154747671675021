import VueGoogleMaps from '@fawmi/vue-google-maps'

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import DocsCallout from '@/components/DocsCallout'
import DocsExample from '@/components/DocsExample'
import './axios/axios.js'
import VueSweetalert2 from 'vue-sweetalert2';
// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css'


const app = createApp(App)
app.use(VueSweetalert2)

// app.use(VueGoogleMaps, {
//     load: {
//       key: "AIzaSyCM3sDRbXbmj1gM89Le6yAsgi7mwaGE4zQ"

//     }
//   });

app.use(store)
app.use(router)
app.use(CoreuiVue)
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('DocsCallout', DocsCallout)
app.component('DocsExample', DocsExample)

app
  .use(VueGoogleMaps, {
    load: {
      key: 'AIzaSyCM3sDRbXbmj1gM89Le6yAsgi7mwaGE4zQ',
      libraries: 'places',
    },
  })
  .mount('#app')
